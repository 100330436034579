import React from 'react';
import PropTypes from 'prop-types';
import DefaultPicture, {
  propTypes as pictureProps,
} from '../../../components/01_atoms/DefaultPicture';
import FormattedText from '../../../components/01_atoms/FormattedText';

import styles from './index.module.scss';

const BBHeroStraplineWithDecoration = ({ image, decoration, text = '' }) => (
  <div className={`bb ${styles['bb-hero-strapline-with-decoration']}`}>
    <div className={styles['bb-hero-strapline-with-decoration__image']}>
      {/* TODO: Add 'preload' array to picture. */}
      {image && <DefaultPicture {...image} />}

      {decoration && decoration === 'stars' && (
        <div className={styles['bb-hero-strapline-with-decoration__stars']}>
          <div className={styles['bb-hero-strapline-with-decoration__stars-container']}>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
              <symbol id="hero-stars" viewBox="0 0 86 86">
                <path
                  d="M64.35,85.28c-3.25-11.88-3.09-17.22-17.05-19.5-3.24-.71-2.68-.38-3.15-1.76,1.02-.94,.06-.48,3.35-1.26,13.28-3.54,12.65-3.54,16.21-17.83h0c.55-1.99,2.27-1.76,2.58,1.69,1.04,5.53,2.51,9.51,4.53,11.47,1.87,2.98,10.7,4.24,14.91,5.74l-.44,.89c-2.18,1.41-11.87,2.68-13.3,4.76-5.68,3.47-4.52,15.08-7.63,15.79Zm-14.49-21c10.01,2.58,12.45,4.84,14.96,15,2.57-10.53,5.49-12.59,14.92-14.97-10.17-2.54-12.41-4.98-14.95-15.06-2.83,10.63-4.4,12.14-14.94,15.03ZM30.84,.73C27.63,23.13,19.99,28.32,.29,31.41c-.13,.05,1.14,.38,2.85,.72,21.17,3.92,23.8,10.09,27.69,30.06,4.56-19.97,6.12-25.89,27.51-29.98,1.87-.38,3.29-.75,3.16-.8-21.58-3.45-26.84-9.31-30.65-30.68Z"
                  fill="#fff"
                />
              </symbol>
            </svg>
            <svg className={styles['bb-hero-strapline-with-decoration__stars-first']}>
              <use xlinkHref="#hero-stars" width="86" height="86" />
            </svg>
            <svg className={styles['bb-hero-strapline-with-decoration__stars-second']}>
              <use xlinkHref="#hero-stars" width="126" height="126" />
            </svg>
          </div>
        </div>
      )}
    </div>

    <div className={styles['bb-hero-strapline-with-decoration__copy']}>
      <div className={styles['bb-hero-strapline-with-decoration__copy-container']}>
        <div className="container">
          {text && (
            <FormattedText
              className={styles['bb-hero-strapline-with-decoration__copy-text']}
              text={text}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);

BBHeroStraplineWithDecoration.propTypes = {
  image: PropTypes.shape(pictureProps).isRequired,
  decoration: PropTypes.oneOf(['stars', '']).isRequired,
  text: PropTypes.string,
};

export default BBHeroStraplineWithDecoration;
